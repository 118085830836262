import '@wangeditor/editor/dist/css/style.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js';
import isDisable from './utils/directive';
import store from './vuex/index';
import { Message } from 'element-ui';
import { routerObj } from '@/utils/routerObj';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(isDisable);

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    let ruleType = "";
    if(store && store.state && store.state.authData) {
        const data = JSON.parse(store.state.authData)
        ruleType = data.roleTypeId
    }
    const ruleList = routerObj;
    if (to.name == 'login' || to.name == 'register' || to.name == 'welcome') {
        next();
    } else if (ruleList[ruleType]) {
        if (ruleList[ruleType].indexOf(to.name) != -1) {
            next();
        } else {
            Message.error('你没有该功能访问权限');
        }
    } else {
        next();
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
