import demoModules from "@/vuex/modules/demoModules";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
    demo: "demoData",
    regionList: [], // 区域列表
    authData: localStorage.getItem("userData"), // 认证数据
    userName: localStorage.getItem("userName"), // 用户名
    agreementTypeList: [
        { label: "用户协议", value: "用户协议" },
        { label: "隐私协议", value: "隐私协议" },
    ],
    scoreRuleList: [
        { label: "积分说明", value: "积分说明" },
        { label: "兑换说明", value: "兑换说明" },
    ],
    userRole: [
        { label: "镇管理员" },
        { label: "村级管理员" },
        { label: "网格长" },
    ],
};

const getters = {
    demo: (state) => state.demo,
    regionList: (state) => state.regionList,
};

const mutations = {
    updateDemo(state, data) {
        state.demo = data;
    },
    updateRegionList(state, data) {
        state.regionList = data;
    },
    updateAuthData(state, data) {
        localStorage.setItem("userData", data);
        state.authData = data;
    }, // 更新认证数据
    deleteAuthData(state) {
        localStorage.removeItem("userData");
        state.authData = "";
    }, // 删除认证数据
    setUserName(state, data) {
        localStorage.setItem("userName", data);
        state.userName = data;
    }, // 设置用户名
};

export default new Vuex.Store({
    modules: {
        demoModules,
    },
    state,
    mutations,
    getters,
});
