import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../view/homeIndex/homeIndex.vue'),
        redirect: '/welcome',
        meta: {
            title: '首页'
        },
        children: [
            {
                path: '/login',
                name: 'login',
                meta: {
                    title: '登录',
                    isHide: true
                },
                component: () =>
                    import('../view/auth/login.vue')
            },
            {
                path: '/register',
                name: 'register',
                meta: {
                    title: '注册',
                    isHide: true
                },
                component: () =>
                    import('../view/auth/register.vue')
            },
            {
                path: '/welcome',
                name: 'welcome',
                component: () =>
                    import('../view/welcomeContent/welcomeContent.vue'),
                meta: {
                    title: '欢迎页',
                    isHide: true
                }
            },
            {
                path: '/pioneer',
                name: 'pioneer',
                meta: {
                    title: '志愿者管理',
                    icon: 'el-icon-s-flag'
                },
                component: { render: (h) => h('router-view') },
                children: [
                    {
                        path: '/pioneer/list',
                        name: 'pioneerList',
                        meta: {
                            title: '志愿者列表'
                        },
                        component: () => import('../view/pioneer/list.vue')
                    }
                ]
            },
            {
                path: '/shop',
                name: 'shop',
                meta: {
                    title: '积分商城',
                    icon: 'el-icon-s-shop'
                },
                component: { render: (h) => h('router-view') },
                redirect: '/shop/category/list',
                children: [
                    {
                        path: '/shop/category/list',
                        name: 'categoryList',
                        meta: {
                            title: '商品分类'
                        },
                        component: () => import('../view/shop/category/list.vue')
                    },
                    {
                        path: '/shop/item/list',
                        name: 'itemList',
                        meta: {
                            title: '商品列表'
                        },
                        component: () => import('../view/shop/item/list.vue')
                    },
                    {
                        path: '/shop/item/edit',
                        name: 'editItem',
                        meta: {
                            title: '编辑商品',
                            isHide: true
                        },
                        component: () => import('../view/shop/item/edit.vue')
                    },
                    {
                        path: '/shop/order',
                        name: 'order',
                        meta: {
                            title: '商品订单'
                        },
                        component: () => import('../view/shop/order/list.vue')
                    },
                    {
                        path: '/shop/address',
                        name: 'address',
                        meta: {
                            title: '商城地址'
                        },
                        component: () => import('../view/shop/address/index.vue')
                    },
                    {
                        path: '/shop/points-description',
                        name: 'pointsDescription',
                        meta: {
                            title: '积分说明'
                        },
                        component: () => import('../view/shop/description/description.vue')
                    }, {
                        path: '/shop/exchange/list',
                        name: 'scoreExchangeList',
                        meta: {
                            title: '积分兑换'
                        },
                        component: () => import('../view/shop/exchange/list.vue')
                    },
                    {
                        path: '/shop/points-description-edit',
                        name: 'pointsDescriptionEdit',
                        meta: {
                            title: '编辑积分说明',
                            isHide: true
                        },
                        component: () => import('../view/shop/description/descriptionAddOrEdit.vue')
                    }, {
                        path: '/shop/notice/list',
                        name: 'shopNoticeList',
                        meta: {
                            title: '商城通知'
                        },
                        component: () => import('../view/shop/notice/list.vue')
                    }
                ]
            },
            {
                path: '/agreement',
                name: 'agreement',
                meta: {
                    title: '协议管理',
                    icon: 'el-icon-document'
                },
                component: { render: (h) => h('router-view') },
                redirect: '/agreement/list',
                children: [
                    {
                        path: '/agreement/list',
                        name: 'agreementList',
                        meta: {
                            title: '协议列表'
                        },
                        component: () => import('../view/agreement/agreementList.vue')
                    },
                    {
                        path: '/agreement/edit',
                        name: 'agreementEdit',
                        meta: {
                            title: '编辑协议',
                            isHide: true
                        },
                        component: () => import('../view/agreement/agreementEditOrAdd.vue')
                    }
                ]
            },
            {
                path: '/system',
                name: 'system',
                meta: {
                    title: '系统管理',
                    icon: 'el-icon-setting'
                },
                component: { render: (h) => h('router-view') },
                redirect: '/system/sysRole',
                children: [
                    {
                        path: '/system/sysRole',
                        name: 'sysRole',
                        meta: {
                            title: '角色管理'
                        },
                        component: () => import('../view/system/sysRole.vue')
                    },
                    // {
                    //     path: '/system/sysUser',
                    //     name: 'sysUser',
                    //     meta: {
                    //         title: '人员管理'
                    //     },
                    //     component: () => import('../view/system/sysUser.vue')
                    // },
                    {
                        path: '/system/about',
                        name: 'about',
                        meta: {
                            title: '关于我们'
                        },
                        component: () => import('../view/system/about/about.vue')
                    },
                    {
                        path: '/system/about-editOrAdd',
                        name: 'aboutEdit',
                        meta: {
                            title: '关于我们',
                            isHide: true
                        },
                        component: () => import('../view/system/about/aboutAddOrEdit.vue')
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        name: '404',
        component: () => import('../view/404/noFound.vue'),
        meta: {
            title: '404'
        }
    }
];

// 解决相同路由情况下跳转报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
